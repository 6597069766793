import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { RefreshService } from 'src/app/core/services/refresh.service';

@Component({
    templateUrl: './main-layout.component.html',
    styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit {
    accountMenuItems: MenuItem[] | undefined;
    sidebarShrinked: boolean = false;
    menuToggled: boolean = false;
    menuClass: string = '';

    constructor(
        public refreshService: RefreshService,
        public authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.accountMenuItems = [
            {
                label: 'My profile',
                routerLink: '/main/account/profile',
                icon: 'pi pi-user',
            },
            {
                label: 'Log out',
                icon: 'pi pi-sign-out',
                command: (): void => void this.authService.logout(),
            },
        ];
    }

    toggleMenu(): void {
        this.menuToggled = !this.menuToggled;

        if (this.menuToggled) this.menuClass = 'show';
        else {
            this.menuClass = 'show  hiding';
            setTimeout(() => {
                this.menuClass = '';
            }, 300);
        }
    }
}
