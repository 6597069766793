import { Injectable } from '@angular/core';
import { RubyApiProxyService } from './ruby-api-proxy.service';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { FeatureFlagsService } from './feature-flags.service';
import {
    AccessTokenResponse,
    AuthServiceProxy,
    CompleteRegistrationCommand,
    RefreshTokensRequest,
    SignInRequest,
} from 'src/app/modules/shared/service-proxies/service-proxies';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { User, UserPermission } from '../models/user';

interface RubyJwt {
    user_id: number;
    first_name: string;
    permissions: UserPermission[];
}

type TokenSource = 'DotNet' | 'Ruby';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly tokenStorageService = new TokenStorageService();

    private _user: User | null = null;

    get isLoggedIn(): boolean {
        return !!this.tokenStorageService.jwtToken && !!this._user;
    }
    get user(): User | null {
        return this._user;
    }

    constructor(
        private authServiceProxy: AuthServiceProxy,
        private featureFlagsService: FeatureFlagsService,
        private router: Router,
        private rubyApiProxyService: RubyApiProxyService,
    ) { }

    async init(): Promise<void> {
        if (
            (this.featureFlagsService.useDotNetBackEnd &&
                this.tokenStorageService.tokenSource === 'Ruby') ||
            (!this.featureFlagsService.useDotNetBackEnd &&
                this.tokenStorageService.tokenSource === 'DotNet')
        ) {
            this.tokenStorageService.clearTokens();
        }
        if (!this.featureFlagsService.useDotNetBackEnd) {
            await this.verifyRubySession();
        } else {
            await this.setUser();
        }
    }

    async getJwt(): Promise<string | null> {
        if (
            this.tokenStorageService.expiration &&
            Date.now() >= this.tokenStorageService.expiration
        ) {
            await this.refreshToken();
        }

        return this.tokenStorageService.jwtToken;
    }

    hasPermission(permission: UserPermission): boolean {
        return this.user?.permissions.includes(permission) ?? false;
    }

    async login(request: SignInRequest): Promise<void> {
        if (!this.featureFlagsService.useDotNetBackEnd) {
            return;
        }

        const res = await firstValueFrom(this.authServiceProxy.signIn(request));
        await this.updateTokens(res);
    }

    async register(
        userId: string,
        code: string,
        password: string,
    ): Promise<void> {
        if (!this.featureFlagsService.useDotNetBackEnd) {
            return;
        }

        const res = await firstValueFrom(
            this.authServiceProxy.completeRegistration(
                CompleteRegistrationCommand.fromJS({
                    userId: userId,
                    code: code,
                    password: password,
                }),
            ),
        );
        await this.updateTokens(res);
    }

    async logout(): Promise<void> {
        if (this.featureFlagsService.useDotNetBackEnd) {
            this.tokenStorageService.clearTokens();
            await this.setUser();
            await this.router.navigateByUrl('/auth/login');
        } else {
            await this.rubyApiProxyService.logout();
            document.location.href = environment.rubyPortalBaseUrl;
        }
    }

    async refreshToken(): Promise<void> {
        if (
            this.featureFlagsService.useDotNetBackEnd &&
            this.tokenStorageService.refreshToken
        ) {
            try {
                const res = await firstValueFrom(
                    this.authServiceProxy.refreshTokens(
                        new RefreshTokensRequest({
                            refreshToken: this.tokenStorageService.refreshToken,
                        }),
                    ),
                );
                await this.updateTokens(res);
            } catch {
                this.tokenStorageService.clearTokens();
            }
        } else if (!this.featureFlagsService.useDotNetBackEnd) {
            await this.verifyRubySession();
        }
    }

    async updateTokens(res: AccessTokenResponse): Promise<void> {
        this.tokenStorageService.setTokens(
            'DotNet',
            res.expiresIn,
            res.accessToken ?? '',
            res.refreshToken,
        );
        await this.setUser();
    }

    private async verifyRubySession(): Promise<void> {
        try {
            const result = await this.rubyApiProxyService.verifyUser();
            this.tokenStorageService.setTokens(
                'Ruby',
                30 * 60, // Ruby tokens expire after 30 seconds
                result.token,
                'not applicable - handled by cookie',
            );
        } catch {
            this.tokenStorageService.clearTokens();
        }
        await this.setUser();
    }

    private async setUser(): Promise<void> {
        if (!this.tokenStorageService.jwtToken) {
            this._user = null;
            return;
        }

        if (this.featureFlagsService.useDotNetBackEnd) {
            const claims = await firstValueFrom(this.authServiceProxy.claims());
            this._user = User.fromClaims(claims);
        } else {
            const decoded = jwtDecode<JwtPayload & RubyJwt>(
                this.tokenStorageService.jwtToken,
            );
            this._user = new User(
                decoded.user_id,
                decoded.first_name,
                '',
                decoded.permissions,
                true,
            );
        }
    }
}

class TokenStorageService {
    private readonly EXPIRATION_KEY = 'auth-token-expiration';
    private readonly JWT_TOKEN_KEY = 'auth-token';
    private readonly REFRESH_TOKEN_KEY = 'auth-refresh-token';
    private readonly TOKEN_SOURCE_KEY = 'auth-token-source';

    private _expiration: number | null = null;
    private _jwtToken: string | null = null;
    private _refreshToken: string | null = null;
    private _tokenSource: TokenSource | null = null;

    get expiration(): number | null {
        if (!this._expiration) {
            const expirationString = localStorage.getItem(this.EXPIRATION_KEY);
            if (expirationString) {
                this._expiration = parseInt(expirationString);
            }
        }

        return this._expiration;
    }
    get jwtToken(): string | null {
        if (!this._jwtToken) {
            this._jwtToken = localStorage.getItem(this.JWT_TOKEN_KEY);
        }

        return this._jwtToken;
    }
    get refreshToken(): string | null {
        if (!this._refreshToken) {
            this._refreshToken = localStorage.getItem(this.REFRESH_TOKEN_KEY);
        }

        return this._refreshToken;
    }
    get tokenSource(): TokenSource | null {
        if (!this._tokenSource) {
            this._tokenSource = localStorage.getItem(
                this.TOKEN_SOURCE_KEY,
            ) as TokenSource;
        }

        return this._tokenSource;
    }

    setTokens(
        tokenSource: TokenSource,
        validForSeconds: number,
        jwtToken: string,
        refreshToken: string | null,
    ): void {
        this._tokenSource = tokenSource;
        //Reducing expiration to avoid fringe case where token expires after we initiate a server request but before the server handles it
        this._expiration = Date.now() + (validForSeconds - 60) * 1000;
        this._jwtToken = jwtToken;
        this._refreshToken = refreshToken;
        localStorage.setItem(this.TOKEN_SOURCE_KEY, this._tokenSource);
        localStorage.setItem(this.EXPIRATION_KEY, this._expiration.toString());
        localStorage.setItem(this.JWT_TOKEN_KEY, this._jwtToken);
        if (this._refreshToken) {
            localStorage.setItem(this.REFRESH_TOKEN_KEY, this._refreshToken);
        } else {
            localStorage.removeItem(this.REFRESH_TOKEN_KEY);
        }
    }

    clearTokens(): void {
        this._expiration = null;
        this._jwtToken = null;
        this._refreshToken = null;
        this._tokenSource = null;
        localStorage.removeItem(this.EXPIRATION_KEY);
        localStorage.removeItem(this.JWT_TOKEN_KEY);
        localStorage.removeItem(this.REFRESH_TOKEN_KEY);
        localStorage.removeItem(this.TOKEN_SOURCE_KEY);
    }
}
